import { Component, Input } from '@angular/core';
import { CheckCertDto } from "./check-cert-dto";



@Component({
	selector: 'app-check-cert-result',
	templateUrl: './check-cert-result.component.html',
	styleUrls: ['./check-cert-result.component.scss']

})
export class CheckcertResultComponent {


	@Input() public checkCert: CheckCertDto;
}
