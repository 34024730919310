
export class CheckCertDto {
	firstName: string;
	lastName: string;
	middleName: string;
	awardName: string;
	awardDate: Date;
	certificatePrefix: string;
	certificateNumber: number;
	qualificationNumber: string;

}
