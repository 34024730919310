import { Injectable } from '@angular/core';
import { RequestService } from "../common/request.service";
import { CheckCertDto } from "./check-cert-dto";


@Injectable()
export class CheckCertService {

	constructor(private requestService: RequestService) { }

	public async checkCert(surname: string, certificateIdentifier: string): Promise<CheckCertDto> {

		return await this.requestService.get<CheckCertDto>(
			`api/CheckCert?surname=${surname}&certificateIdentifier=${certificateIdentifier}`);
	}
}
