import { Component } from "@angular/core";

@Component({
	selector: "app-layout",
	templateUrl: "./layout.component.html",
    styleUrls: ["./layout.component.scss"]
})
export class LayoutComponent {
	public navbarOpen: boolean = false;

	year: Number = new Date().getFullYear();
}
