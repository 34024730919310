import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { CheckCertService } from "./check-cert/check-cert.service";
import { CommonModule } from "./common/common.module";
import { environment } from "../environments/environment";
import { LayoutComponent } from "./layout/layout.component";
import { CheckcertResultComponent } from "./check-cert/check-cert-result.component";
import { CheckCertComponent } from "./check-cert/check-cert.component";

@NgModule({
	declarations: [
		AppComponent,
		LayoutComponent,
		CheckCertComponent,
		CheckcertResultComponent
	],
	imports: [
		BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
		HttpClientModule,
		FormsModule,
		CommonModule
	],
	providers: [
		CheckCertService,
		{ provide: "AuthApiBaseUrl", useValue: environment.AuthApiBaseUrl },
		{ provide: "LoginPageUrl", useValue: environment.AuthApiBaseUrl },
		{ provide: "UserApiBaseUrl", useValue: environment.AuthApiBaseUrl },
		{ provide: "CheckCertBaseUrl", useValue: environment.CheckCertBaseUrl },
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
