import { Injectable, Inject } from "@angular/core";
import { AuthenticatedService } from "angular-highfieldauth";
import { APIResponse} from "angular-highfieldwebapi";

@Injectable()
export class RequestService {

	constructor(private authenticatedService: AuthenticatedService,
		@Inject("CheckCertBaseUrl") private checkCertBaseUrl: string) { }

	public async get<T>(url: string): Promise<T> {

		const response: APIResponse = await this.authenticatedService.getAsync(this.checkCertBaseUrl, url, "application/json");

		if (response.responseCode === 200) {
			return <T>response.responseData;
		}

		return null;
	}
}
