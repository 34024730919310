import { Component, OnInit } from '@angular/core';
import { CheckCertDto } from "./check-cert-dto";
import { CheckCertService } from "./check-cert.service";
import { Directive, Input } from '@angular/core';


@Component({
	selector: 'app-check-cert',
	templateUrl: './check-cert.component.html',
	styleUrls: ['./check-cert.component.scss']
})
export class CheckCertComponent implements OnInit {

	public surname: string;
	public certificateNo: string;
	public error = "";

	public checkCert: CheckCertDto;

	ngOnInit() {
		window.location.replace('https://www.highfieldqualifications.com/checkcert');
	}

	constructor(private checkCertService: CheckCertService) { }

	onKeydown(event) {
		if (event.key === "Enter") {
			this.onVerify();

		}
	}

	public async onVerify(): Promise<void> {

		try {


			if (!this.surname) {

				throw new Error("You must enter a surname");

			}

			if (!this.certificateNo) {

				throw new Error("You must enter a certificate number");
			}

			let checkCert: CheckCertDto = await this.checkCertService.checkCert(this.surname, this.certificateNo);

			if (!checkCert) {
				throw new Error("Certificate not found");
			} else {
				this.checkCert = checkCert;
			}

		} catch (error) {
			this.error = error;
		}

	}



}
