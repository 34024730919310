import { NgModule } from "@angular/core";
import { RequestService } from "./request.service";
import { AngularHighfieldauthModule, AuthenticatedService } from 'angular-highfieldauth';
import { AngularHighfieldwebapiModule } from "angular-highfieldwebapi";

@NgModule({
	imports: [
		AngularHighfieldauthModule,
		AngularHighfieldwebapiModule
	],
	providers: [
        AuthenticatedService,
		RequestService
	]
})
export class CommonModule { }
